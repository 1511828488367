var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column align-items-center" },
    [
      _c("h2", { staticClass: "text-primary" }, [_vm._v(_vm._s(_vm.msg))]),
      _c(
        "form",
        {
          staticClass: "w-50 px-5 d-flex flex-column align-items-center",
          attrs: { id: "payment-form" }
        },
        [
          _c("div", { ref: "card", staticClass: "form-control m-2" }),
          _c(
            "div",
            { staticClass: "payButtons" },
            [
              _c(
                "b-button",
                {
                  staticClass: "btn button-pay",
                  attrs: { id: "completePayment", disabled: _vm.lockSubmit },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.payBtn($event)
                    }
                  }
                },
                [_vm._v("Pagar " + _vm._s(_vm.amountLocal) + "€")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "btn button-cancel-pay",
                  attrs: { id: "cancelPayment", variant: "outline-danger" },
                  on: { click: _vm.resetCardData }
                },
                [_vm._v("Cancelar")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-alert",
        { staticClass: "w-75 mt-2", attrs: { show: _vm.payOneEuroLocal } },
        [
          _c("p", [_vm._v("¿Por qué hay que pagar 1€?")]),
          _vm._v(
            "No queremos que corras ningún riesgo. Hacemos un cargo de 1€ para poder realizar el cargo mensual a tu tarjeta de manera segura y sin almacenar los datos en nuestro sistema. El euro de activación se te descontará de la primera factura mensual.\n  "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }