<template>
  <div class="d-flex flex-column align-items-center">
    <h2 class="text-primary">{{msg}}</h2>
    <form id="payment-form" class="w-50 px-5 d-flex flex-column align-items-center">
      <div ref="card" class="form-control m-2">
        <!-- A Stripe Element will be inserted here. -->
      </div>
      <div class="payButtons">
        <b-button id="completePayment" :disabled="lockSubmit" class="btn button-pay" v-on:click.prevent="payBtn">Pagar {{amountLocal}}€</b-button>
        <b-button id="cancelPayment" class="btn button-cancel-pay" variant="outline-danger" @click="resetCardData">Cancelar</b-button>
      </div>
    </form>
    <b-alert class="w-75 mt-2" :show="payOneEuroLocal">
      <p>¿Por qué hay que pagar 1€?</p>No queremos que corras ningún riesgo. Hacemos un cargo de 1€ para poder realizar el cargo mensual a tu tarjeta de manera segura y sin almacenar los datos en nuestro sistema. El euro de activación se te descontará de la primera factura mensual.
    </b-alert>
  </div>
</template>
<script>
import { loadStripe } from '@stripe/stripe-js/pure'
import Amplify from 'aws-amplify'
import { EventBus } from '@/event-bus'

export default {
  props: ['amount', 'invoice', 'payOneEuro', 'protectingFlag'],
  data() {
    return {
      msg: 'Datos de la tarjeta',
      lockSubmit: false,
      stripeData: {
        stripe: '',
        card: '',
        clientSecret: ''
      },
      cardData: {
        amount: 0,
        invoiceId: '',
      },
      notiTime: 8000
    }
  },
  methods: {
    userActions() {
      if (this.$acl.get[0] !== "referer") {
        this.$acl.change('payment')
      }
      this.$store.dispatch('updateCreditCardValid', true)
    },
    showPaymentOk() {
      this.showNoti('El pago se ha recibido correctamente', 'Pago recibido', 'success', this.notiTime)
    },
    showPaymentOneEuroOk() {
      this.showNoti('Hemos recibido el pago correctamente, ahora podrás proteger tus cuentas cuando quieras sin procuparte de nada, nosotros nos encargamos de todo.', 'Enhorabuena', 'success', 15000)
    },
    showPaymentKo() {
      this.showNoti('Por favor vuelva a intentarlo con otra tarjeta', 'Error procesando pago', 'danger', this.notiTime)
    },
    showNoti(text, title, color, time) {
      this.$vs.notify({
        title: title,
        text: text,
        color: color || 'primary',
        position: 'top-center',
        time: time || 8000
      })
    },
    resetCardData() {
      this.stripeData.stripe = ''
      this.stripeData.card = ''
      this.stripeData.clientSecret = ''
      this.cardData.amount = 0
      this.cardData.invoiceId = ''
      EventBus.$emit('visibleCard-state', true)
    },
    payWithCard() {
      this.$vs.loading()
      this.stripeData.stripe
        .confirmCardPayment(this.stripeData.clientSecret, {
          payment_method: {
            card: this.stripeData.card
          }
        })
        .then(result => {
          if (result.error) {
            // Show error to your customer
            this.showPaymentKo()
            this.lockSubmit = false
            this.$store.dispatch('updateCreditCardValid', false)
            EventBus.$emit('user-creditCardValid', false)
            this.resetCardData()
            this.$vs.loading.close()
          } else {
            // The payment succeded!
            this.userActions()
            if (this.payOneEuroLocal) {
              if (this.$store.state.user.protectingFlag.id != 0) {
                if (this.$store.state.user.protectingFlag.id === 'all') {
                  EventBus.$emit('protectAll')
                  this.$router.push('/google-accounts')
                } else {
                  const proParams = {
                    gAccount: this.$store.state.user.protectingFlag.id,
                    protecting: true
                  }
                  this.$store.dispatch("updateAccountProtecting", proParams)
                  Amplify.Auth.currentSession()
                    .then(apiSession => {
                      this.$axios
                        .post("/useraction", proParams, {
                          headers: {
                            Authorization: "Bearer " + apiSession.idToken.jwtToken
                          }
                        }).then(response => {
                          if (response.status === 200) {
                            this.$router.push('/google-accounts')
                          }
                        })
                    })
                }
                this.showPaymentOneEuroOk()
              } else {
                this.showPaymentOneEuroOk()
              }
            } else {
              this.showPaymentOk()
            }
            this.lockSubmit = false
            if (this.cardData.amount > 1) {
              EventBus.$emit('recharge-Pay-Table')
            }
            this.$vs.loading.close()
            this.resetCardData()
          }
        })
    },
    payBtn() {
      this.lockSubmit = true
      this.payWithCard()
      this.$gtm.push({ event: 'Finalizar_Pago', userId: this.$store.state.user.id })
    },
    generateCardData(data) {
      var _0x23bb = ["\x73\x74\x72\x69\x6E\x67\x69\x66\x79", "", "\x72\x65\x70\x6C\x61\x63\x65", "\x69\x64", "\x75\x73\x65\x72", "\x73\x74\x61\x74\x65", "\x24\x73\x74\x6F\x72\x65", "\x65\x6E\x63\x72\x79\x70\x74", "\x41\x45\x53", "\x43\x72\x79\x70\x74\x6F\x4A\x53"]; const e1 = JSON[_0x23bb[0]](data); const e2 = (this[_0x23bb[6]][_0x23bb[5]][_0x23bb[4]][_0x23bb[3]])[_0x23bb[2]](/-/g, _0x23bb[1]); const e3 = this[_0x23bb[9]][_0x23bb[8]][_0x23bb[7]](e1, e2).toString(); return e3
    },
  },
  computed: {
    amountLocal: {
      get() {
        return this.amount
      },
    },
    invoiceLocal: {
      get() {
        return this.invoice
      }
    },
    payOneEuroLocal: {
      get() {
        return this.payOneEuro
      },
    },
  },
  mounted: async function () {
    //INI Authorize op
    Amplify.Auth.currentSession().then(apiSession => {
      const cardAuthData = {
        cardData: this.generateCardData(this.cardData)
      }
      this.$axios.post('/payment/auth', cardAuthData, {
        headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
      }).then(response => {
        this.$vs.loading.close()
        if (response && response.data) {
          if (response.data.hasOwnProperty('p1')) {
            this.stripeData.clientSecret = response.data.p1
          } else {
            this.$vs.loading.close()
            this.showNoti('Por favor vuelva a intentarlo en unos minutos', 'Error procesando pago', 'danger', this.notiTime)
          }
        } else {
          this.showNoti('Por favor vuelva a intentarlo con otra tarjeta', 'Error procesando pago', 'danger', this.notiTime)
        }
      })
    })
    // FIN Authorize op
    this.cardData.amount = this.amountLocal
    this.cardData.invoiceId = this.invoiceLocal
    this.stripeData.stripe = await loadStripe('pk_live_51HHWz7L87rr8POrlRsXYajpJXuo1fUwVkr3VbJwbzKhtPHOqVzCjlm1gByGVgsGf07Pq8SaNsWLpesNoUUGt3opt00staq3RO0')
    const elements = this.stripeData.stripe.elements()
    const cardParams = {
      hidePostalCode: true,
    }
    this.stripeData.card = elements.create('card', cardParams)
    this.stripeData.card.mount(this.$refs.card)
  },
}
</script>
<style>
.payButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.payButtons > button {
  margin: 15px 15px 5px 15px;
}
</style>