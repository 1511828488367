<template>
  <div class="maindiv">
    <div v-if="visibleCard" class="goWitdh">
      <div class="">
        <vs-tabs :color="colorx">
          <vs-tab label="General">
            <div class="con-tab-ejemplo">
              <div id="prices">
                <div class="pricedata">
                  <div class='priceMain'>
                    <div class="radioOne picked">
                      <div class="infoprice">
                        <span class="tittext">Protege tu inversión eliminando clics fraudulentos:</span>
                      </div>
                      <div v-if="$acl.get[0]==='user'" class="paytypediv">
                        <div>
                          <b-button id="firstPaymentUp" class="button-pay-first" @click="prePay">Protege tus anuncios</b-button>
                        </div>
                      </div>
                      <div class="advantages">
                        <div class="listelem titleelem">
                          <div class="listelemchild">
                            <span class="font-weight-bold">
                              Ventajas:
                            </span>
                            <span class="font-weight-bold">
                              Modo Análisis
                              <br />Gratuito
                            </span>
                            <span class="font-weight-bold">
                              Modo Protección
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Monitorización 24/7
                            </span>
                            <span>
                              <feather-icon icon="CheckIcon" class="text-success iconsrow cursor-pointer"></feather-icon>
                            </span>
                            <span>
                              <feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon>
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Cuentas ilimitadas
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Detección de huella digital
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Inteligencia colectiva
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Informes semanales
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Soporte por e-mail y chat
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Tecnología predictiva
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Listas negra IP
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="XIcon" class="text-danger iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Reglas de bloqueo
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="XIcon" class="text-danger iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Bloqueo de Proxy y VPN
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="XIcon" class="text-danger iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Bloqueo automático de IPs
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="XIcon" class="text-danger iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Bloqueo de dominios cruzados
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="XIcon" class="text-danger iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                        <div class="listelem">
                          <div class="listelemchild">
                            <span>
                              Bloqueo de rangos
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="XIcon" class="text-danger iconsrow cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                            <span>
                              &nbsp; &nbsp; &nbsp;<feather-icon icon="CheckIcon" class="text-success iconsrow ml-5 cursor-pointer"></feather-icon> &nbsp; &nbsp; &nbsp;
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="imgTextInfo">
                        <p>Paga solo por el presupuesto que proteges cada mes, con un mínimo de 20€.</p>
                        <p>Si no proteges en todo el mes, ¡no pagarás nada!</p>
                      </div>
                      <div class="imageCont">
                        <img src="../assets/images/pages/payImage.png" width="85%">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="$acl.get[0]==='user'" class="paytypediv">
                <div>
                  <b-button id="firstPayment" class="button-pay-first" @click="prePay">Protege tus anuncios</b-button>
                </div>
              </div>
              <div class="faqs">
                <div>
                  <vs-collapse accordion>
                    <vs-collapse-item>
                      <div slot="header">
                        1. ¿En qué se diferencia el modo gratuito del modo de protección?
                      </div>
                      Analizar tu tráfico es gratis, siempre podrás saber qué porcentaje de tus clics son fraudulentos y el dinero que estás perdiendo con ellos. Si activas el modo de protección, dejarás de perder ese dinero y tus anuncios mejorarán el rendimiento estando protegidos.
                    </vs-collapse-item>
                    <vs-collapse-item>
                      <div slot="header">
                        2. ¿Cuál es el compromiso mínimo de pago?
                      </div>
                      No existe compromiso mínimo, se te cobrará únicamente el porcentaje del tráfico protegido, mes a mes, cuando tengas activada la función de proteger en tu panel de administración y puedes desactivarla para dejar de pagar, en cualquier momento.
                    </vs-collapse-item>
                    <vs-collapse-item>
                      <div slot="header">
                        3. ¿Puedo cancelar mi suscripción en cualquier momento?
                      </div>
                      Sí, puedes cancelar tu suscripción en cualquier momento. Si no estás 100% satisfecho con el servicio, puedes cancelar tu suscripción y dejar de pagar en cualquier momento desactivando la opción de proteger tu cuenta en la pestaña “cuentas”.
                    </vs-collapse-item>
                  </vs-collapse>
                </div>
                <div>
                  <vs-collapse accordion>
                    <vs-collapse-item>
                      <div slot="header">
                        4. ¿Se me cobrará automáticamente la suscripción mensual?
                      </div>
                      Sí, una vez haya vencido el mes, el sistema calculará la cantidad correspondiente a el presupuesto total que hayas protegido en el mes que ha vencido y se te pasará un cargo automáticamente a tu tarjeta el primer día del mes siguiente.
                    </vs-collapse-item>
                    <vs-collapse-item>
                      <div slot="header">
                        5. ¿Qué formas de pago aceptáis?
                      </div>
                      Aceptamos el pago con tarjetas VISA, MASTERCARD y AMERICAN EXPRESS. Si su tarjeta tiene la tecnología 3D Secure, se le pedirá confimación para completar la transacción. Para utilizar un método de pago diferente, escribe a soporte@clickdefense.io.
                    </vs-collapse-item>
                    <vs-collapse-item>
                      <div slot="header">
                        6. ¿En qué moneda están los precios?
                      </div>
                      Dada nuestra pertenencia a la Unión Europea, todos nuestros precios y facturas se encuentran expresados en Euros (€). Si tu moneda local es diferente al Euro, el titular de la tarjeta puede elegir pagar en su propia moneda.
                    </vs-collapse-item>
                  </vs-collapse>
                </div>
              </div>
            </div>
          </vs-tab>
          <vs-tab :disabled="$acl.get[0]==='user'" label="Tus Pagos">
            <div class="table">
              <b-table :items="items" :fields="fields" outlined striped sticky-header="80vh">
                <template v-slot:cell(status)="data">
                  <span v-if="data.value==='paid'" class="text-success">Pagado <unicon id='invoiceIcon' name="file-download" fill="#2f1b7a" @click="getInvoice(items[data.index].invoiceId)"></unicon></span>
                  <span v-else><span class="text-danger pr-5">Pendiente</span>
                    <b-button variant="outline-secondary" @click="payBill(items[data.index].amount,items[data.index].invoiceId)">Pagar {{items[data.index].amount}}€</b-button>
                  </span>
                </template>
                <template v-slot:cell(amount)="data">
                  <span>{{items[data.index].amount}} EUR</span>
                </template>
                <template v-slot:cell(payDate)="data">
                  <span v-if="items[data.index].payDate==='Pendiente'" class="text-danger">{{items[data.index].payDate}}</span>
                  <span v-else>{{items[data.index].payDate}}</span>
                </template>
                <template v-slot:table-caption>
                  <b-button id="updateCCard" variant="outline-secondary" @click="prePay">Actualizar Tarjeta</b-button>
                </template>
              </b-table>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </div>
    <div class="w-100" v-else>
      <div v-if="!billInformation">
        <div class="billInfo"><span class="tittext infoprice">DATOS DE FACTURACIÓN</span></div>
        <b-container>
          <b-row>
            <b-col>
              <div class="form-group">
                <label>Empresa:</label>
                <div class="input-group mb-0">
                  <input ref="billName" :data-error="(billErrors.billName)?true:false" v-model="billName" type="text" class="form-control">
                </div>
                <div v-if="billErrors.billName" class="errorCard">
                  <small>{{ billErrors.billName }}</small>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <label>NIF:</label>
                <div class="input-group mb-0">
                  <input ref="billCif" :data-error="(billErrors.billCif)?true:false" v-model="billCif" type="text" class="form-control">
                </div>
                <div v-if="billErrors.billCif" class="errorCard">
                  <small>{{ billErrors.billCif }}</small>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <b-row>
            <b-col cols="8">
              <div class="form-group">
                <label>Dirección:</label>
                <div class="input-group mb-0">
                  <input ref="billAdress" :data-error="(billErrors.billAdress)?true:false" v-model="billAdress" type="text" class="form-control">
                </div>
                <div v-if="billErrors.billAdress" class="errorCard">
                  <small>{{ billErrors.billAdress }}</small>
                </div>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="form-group">
                <label>CP:</label>
                <div class="input-group mb-0">
                  <input ref="billZip" :data-error="(billErrors.billZip)?true:false" v-model="billZip" type="text" class="form-control">
                </div>
                <div v-if="billErrors.billZip" class="errorCard">
                  <small>{{ billErrors.billZip }}</small>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <b-row>
            <b-col>
              <div class="form-group">
                <label>Ciudad:</label>
                <div class="input-group mb-0">
                  <input ref="billCity" :data-error="(billErrors.billCity)?true:false" v-model="billCity" type="text" class="form-control">
                </div>
                <div v-if="billErrors.billCity" class="errorCard">
                  <small>{{ billErrors.billCity }}</small>
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="form-group">
                <label>Provincia:</label>
                <div class="input-group mb-0">
                  <input ref="billProvince" :data-error="(billErrors.billProvince)?true:false" v-model="billProvince" type="text" class="form-control">
                </div>
                <div v-if="billErrors.billProvince" class="errorCard">
                  <small>{{ billErrors.billProvince }}</small>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <b-row>
            <b-col>
              <div class="form-group">
                <label>País:</label>
                <div class="input-group mb-0">
                  <input ref="billCountry" :data-error="(billErrors.billCountry)?true:false" v-model="billCountry" type="text" class="form-control">
                </div>
                <div v-if="billErrors.billCountry" class="errorCard">
                  <small>{{ billErrors.billCountry }}</small>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <div class="billInfo">
          <b-button id="confirmBillInfo" variant="outline-secondary" @click="confirmBillData">Continuar</b-button>
          <b-button id="cancelBillInfo" variant="outline-danger" @click="resetBillData">Cancelar</b-button>
        </div>
      </div>
      <div class="w-100" v-else>
        <div class="w-100">
          <div class="topSpace w-75">
            <StripeVueCard :amount="cardData.amount" :invoice="cardData.invoiceId" :payOneEuro="payOneEuro" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Amplify from 'aws-amplify'
import utils from '@/api/utils.js'
import { EventBus } from '@/event-bus'
import StripeVueCard from '@/layouts/components/StripeVueCard'

export default {
  data() {
    return {
      cardData: {
        amount: 0,
        invoiceId: '',
      },
      billName: '',
      billCif: '',
      billZip: '',
      billCountry: 'España',
      billProvince: '',
      billAdress: '',
      billCity: '',
      billErrors: {},
      billInformation: false,
      visibleCard: true,
      colorx: 'rgb(47,27,122)',
      creditCardValid: false,
      payOneEuro: false,
      fields: [{
        key: "concept",
        label: "Concepto",
      }, {
        key: "amount",
        label: "Cantidad",
      }, {
        key: "payDate",
        label: "Fecha de la factura",
      }, {
        key: "status",
        label: "Estado",
      }],
      items: [],
    }
  },
  methods: {
    showInvoiceKo() {
      this.showNoti('No podemos mostrar su factura, por favor intentelo en unos minutos.', 'Factura no disponible', 'danger', this.notiTime)
    },
    showNoti(text, title, color, time) {
      this.$vs.notify({
        title: title,
        text: text,
        color: color || 'primary',
        position: 'top-center',
        time: time || 8000
      })
    },
    getInvoice(invoiceId) {
      this.$vs.loading()
      Amplify.Auth.currentSession().then(apiSession => {
        // INI Get user
        this.$axios.get(`/invoice/${invoiceId}`, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          this.$vs.loading.close()
          let pdfWindow = window.open('', '_blank')
          pdfWindow.document.write("<html><body style='margin:0;overflow:hidden'><iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(response.data) + "'></iframe></body></html>")
          this.$gtm.push({ event: 'Descarga_Factura', userId: this.$store.state.user.id })
        }).catch(() => {
          //No response or wrong invoice
          this.$vs.loading.close()
          this.showInvoiceKo()
        })
        // FIN Get user
      }).catch(() => {
        //Server connection fail
        this.$vs.loading.close()
        this.showInvoiceKo()
      })
    },
    prePay() {
      this.cardData.amount = 1
      this.visibleCard = false
      if (this.payOneEuro) {
        this.$gtm.push({ event: 'Pagar_Protege_Anuncios', userId: this.$store.state.user.id })
      } else {
        this.$gtm.push({ event: 'Actualizar_tarjeta', userId: this.$store.state.user.id })
      }
    },
    confirmBillData() {
      this.billErrors = {}
      let counter = 0
      if (this.billName === '') {
        this.billErrors.billName = "Es obligatorio rellenar este campo"
        counter++
      }
      if (this.billCif === '') {
        this.billErrors.billCif = "Es obligatorio rellenar este campo"
        counter++
      }
      if (this.billZip === '') {
        this.billErrors.billZip = "Es obligatorio rellenar este campo"
        counter++
      }
      if (this.billCountry === '') {
        this.billErrors.billCountry = "Es obligatorio rellenar este campo"
        counter++
      }
      if (this.billProvince === '') {
        this.billErrors.billProvince = "Es obligatorio rellenar este campo"
        counter++
      }
      if (this.billCity === '') {
        this.billErrors.billCity = "Es obligatorio rellenar este campo"
        counter++
      }
      if (this.billAdress === '') {
        this.billErrors.billAdress = "Es obligatorio rellenar este campo"
        counter++
      }
      if (counter === 0) {
        Amplify.Auth.currentSession().then(apiSession => {
          let billData = {
            billingDetails: {
              name: this.billName,
              cif: this.billCif,
              zip: this.billZip,
              country: this.billCountry,
              province: this.billProvince,
              address: this.billAdress,
              city: this.billCity,
            }
          }
          this.$axios.put(`/user/${this.$store.state.user.id}`, billData, {
            headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
          }).then(response => {
            if (response.status === 200) {
              this.billInformation = true
              this.visibleCard = false
            }
          }).catch(() => {

          })

        }).catch(() => {
        })
      }
    },
    resetBillData() {
      this.billErrors = {}
      this.billName = ''
      this.billCif = ''
      this.billZip = ''
      this.billCountry = 'España'
      this.billProvince = ''
      this.billAdress = ''
      this.billCity = ''
      this.billInformation = false
      this.visibleCard = true
    },
    payBill(amount, invoice) {
      this.cardData.amount = amount
      this.cardData.invoiceId = invoice
      this.visibleCard = false
      this.$gtm.push({ event: 'Pagar_Factura', userId: this.$store.state.user.id })
    },
    transformDataFormat(ctx) {
      let dataItems = { items: [] }
      for (let i = 0; i < ctx.length; i++) {
        dataItems.items.push({
          amount: ctx[i]["amount"].toFixed(2),
          concept: 'Pago del mes de ' + this.$moment(ctx[i]["timestamp"]).format('MMMM'),
          payDate: this.$moment(ctx[i]["invoiceDate"]).format('DD/MM/YYYY'),
          status: ctx[i]["status"],
          invoiceId: ctx[i]["invoiceId"],
        });
      }
      return dataItems.items
    },
    dataTable() {
      Amplify.Auth.currentSession().then(apiSession => {
        const invoicesParams = {
          userId: '0'
        }
        this.$axios.post('/invoices', invoicesParams, {
          headers: { 'Authorization': 'Bearer ' + apiSession.idToken.jwtToken }
        }).then(response => {
          const results = response.data
          const dataitems = this.transformDataFormat(results.items)
          this.items = this.items.concat(dataitems)
        })
      })
    },
    async initialGetUserData(dataUserId) {
      await this.$initMainData(dataUserId)
      this.cardData.amount = 1
      // FIN Get user
    },
  },
  mounted() {
    Amplify.Auth.currentSession().then(apiSession => {
      const paramsCheckAdmin = apiSession.accessToken.payload['cognito:groups']
      this.$store.dispatch('updateAdmin', utils.isAdminUser(paramsCheckAdmin))
      if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount !== '') {
        this.initialGetUserData(this.$store.state.user.id)
        this.$acl.change('admin')
      } else if (this.$store.state.user.isAdmin && this.$store.state.user.mainAccount === '') {
        this.$vs.loading.close()
        this.$acl.change('admin')
        this.$router.push('/admin-page')
      } else {
        this.initialGetUserData('0')
        this.$vs.loading.close()
      }
    })
    EventBus.$on('update-billingInformation', (billingInformation) => {
      this.billInformation = billingInformation
    })
    EventBus.$on('user-creditCardValid', (creditCardValid) => {
      this.creditCardValid = creditCardValid
    })
    EventBus.$on('visibleCard-state', (state) => {
      this.visibleCard = state
    })
    EventBus.$on('recharge-Pay-Table', () => {
      this.items = []
      this.dataTable()
    })
    this.dataTable()
    if (this.$acl.get[0] === 'user') {
      this.payOneEuro = true
    } else {
      this.payOneEuro = false
    }
  },
  components: {
    StripeVueCard
  }
}
</script>
<style>
#invoiceIcon {
  cursor: pointer;
}
.topSpace {
  padding-top: 5%;
  margin: auto;
}
.button-pay {
  background-color: #cd45ff;
  font-weight: bold;
  color: #ffffff;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
}
.button-pay-first {
  background-color: #cd45ff;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  padding: 1rem 3rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.button-cancel-pay {
  font-weight: bold;
  color: #000;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
}
.textar {
  width: 70%;
}
.textar > div {
  overflow-y: auto;
  overflow-x: hidden;
}
.tittext {
  color: #1b0a5f;
  border-radius: 5px 5px 0 0;
}
.smtext {
  font-size: small !important;
}
.listelem {
  font-size: medium;
  border-bottom: 3px solid rgba(199, 209, 215, 0.5);
  border-right: 3px solid rgba(199, 209, 215, 0.5);
  border-left: 3px solid rgba(199, 209, 215, 0.5);
  padding: 0.5%;
}
.titleelem {
  padding: 1.5%;
  background-color: #2f1b7a;
  color: white;
}
.listelemchild {
  display: flex;
  justify-content: space-between;
}
.listelemchild span:nth-child(1) {
  min-width: 50%;
}
.listelemchild span:nth-child(2) {
  width: 25%;
}
.listelemchild span:nth-child(3) {
  width: 25%;
}
.listelemchild span:nth-child(2) > span {
  justify-content: center;
}
.listelemchild span:nth-child(3) > span {
  justify-content: center;
}
.advantages {
  width: 60%;
  margin: 0 auto;
  color: rgba(47, 27, 122, 0.85);
  border-radius: 0 0 5px 5px;
}
.infoprice {
  margin: 0 2% 1% 2%;
  font-size: x-large;
  color: #1b0a5f;
}
.imgTextInfo {
  margin: 2% 2% 0 2%;
  font-size: x-large;
  color: #1b0a5f;
}
.bigtext {
  font-size: x-large;
}
.pricedata {
  width: 30%;
  text-align: center;
}

.picked > label {
  background-color: #2f1b7a !important;
  color: white;
}

#prices {
  width: 100%;
  display: flex;
  flex-direction: column;
}
#prices > span {
  width: 80%;
  text-align: center;
  margin: 1% auto;
}
#prices > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 1%;
}
.maindiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.redsysdiv {
  width: 650px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#card-form {
  width: 650px;
  height: 300px;
}
.paytypediv {
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  flex-direction: column;
}
.paymentdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.paytypediv > div > img {
  width: 15%;
  height: auto;
}
.textspan {
  font-size: 1.3em;
  margin-right: 2%;
}
.priceMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  padding-bottom: 5px;
}
.mainPricing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #2f1b7a;
  box-shadow: 2px #2f1b7a;
  width: 95%;
  margin: 0 auto;
  padding: 2%;
}
.pricingOne,
.pricingTwo,
.pricingThree {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.titlePricing {
  width: 18%;
  margin: auto;
}
.contOne,
.contTwo,
.contThree {
  padding: 1% 3%;
}
.contTwo {
  height: 10px;
  border-color: white;
  border-style: solid;
  border-width: 0px 1.5px 0px 1.5px;
}
.colOne {
  background-color: #26d7d4;
  border-color: white;
  border-style: solid;
  border-width: 0px 1.5px 0px 1.5px;
}
.colTwo {
  background-color: #13afe9;
  border-color: white;
  border-style: solid;
  border-width: 0px 1.5px 0px 1.5px;
}
.colThree {
  background-color: #0089fe;
  border-color: white;
  border-style: solid;
  border-width: 0px 1.5px 0px 1.5px;
}
.colOne,
.colTwo,
.colThree {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.colOne > p,
.colTwo > p,
.colThree > p {
  margin-bottom: 0;
}
.faqs {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 65%;
  margin: 0 auto;
}
.faqsOne {
  width: 50%;
}
.faqsTwo {
  width: 50%;
}
.goWitdh {
  width: 100%;
}
.vs-tabs--btn {
  outline: none !important;
}
.errorCard {
  color: red;
}
.cardData {
  margin-top: 25vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: 425px;
}
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
.wrapper {
  display: flex;
  padding: 25px 15px;
}
@media screen and (max-width: 700px), (max-height: 500px) {
  .wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
}
.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .card-form {
    margin: 0 auto;
  }
}
.card-form__inner {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 10px;
  padding: 35px;
  padding-top: 180px;
}
@media screen and (max-width: 480px) {
  .card-form__inner {
    padding: 25px;
    padding-top: 165px;
  }
}
@media screen and (max-width: 360px) {
  .card-form__inner {
    padding: 15px;
    padding-top: 165px;
  }
}
.card-form__row {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 480px) {
  .card-form__row {
    flex-wrap: wrap;
  }
}
.card-form__col {
  flex: auto;
  margin-right: 35px;
}
.card-form__col:last-child {
  margin-right: 0;
}
@media screen and (max-width: 480px) {
  .card-form__col {
    margin-right: 0;
    flex: unset;
    width: 100%;
    margin-bottom: 20px;
  }
  .card-form__col:last-child {
    margin-bottom: 0;
  }
}
.card-form__col.-cvv {
  max-width: 150px;
}
@media screen and (max-width: 480px) {
  .card-form__col.-cvv {
    max-width: initial;
  }
}
.card-form__group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__group .card-input__input {
  flex: 1;
  margin-right: 15px;
}
.card-form__group .card-input__input:last-child {
  margin-right: 0;
}
.card-form__button {
  width: 100%;
  height: 55px;
  background: #2364d2;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  font-family: "Source Sans Pro", sans-serif;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-form__button {
    margin-top: 10px;
  }
}
.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }
}
@media screen and (max-width: 360px) {
  .card-item {
    height: 180px;
  }
}
.card-item.-active .card-item__side.-front {
  transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
}
.card-item.-active .card-item__side.-back {
  transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
}
.card-item__focus {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}
.card-item__focus:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #08142f;
  height: 100%;
  border-radius: 5px;
  filter: blur(25px);
  opacity: 0.5;
}
.card-item__focus.-active {
  opacity: 1;
}
.card-item__side {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
  transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  backface-visibility: hidden;
  height: 100%;
}
.card-item__side.-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
  z-index: 2;
  padding: 0;
  height: 100%;
}
.card-item__side.-back .card-item__cover {
  transform: rotateY(-180deg);
}
.card-item__bg {
  max-width: 100%;
  display: block;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-item__cover {
  height: 100%;
  background-color: #1c1d27;
  position: absolute;
  height: 100%;
  background-color: #1c1d27;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.card-item__cover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 2, 29, 0.45);
}
.card-item__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 10px;
}
@media screen and (max-width: 480px) {
  .card-item__top {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__top {
    margin-bottom: 15px;
  }
}
.card-item__chip {
  width: 60px;
}
@media screen and (max-width: 480px) {
  .card-item__chip {
    width: 50px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__chip {
    width: 40px;
  }
}
.card-item__type {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__type {
    height: 40px;
    max-width: 90px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__type {
    height: 30px;
  }
}
.card-item__typeImg {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  object-position: top right;
}
.card-item__info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  padding: 10px 15px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__info {
    padding: 10px;
  }
}
.card-item__holder {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 6px;
}
@media screen and (max-width: 480px) {
  .card-item__holder {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.card-item__wrapper {
  font-family: "Source Code Pro", monospace;
  padding: 25px 15px;
  position: relative;
  z-index: 4;
  height: 100%;
  text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
  user-select: none;
}
@media screen and (max-width: 480px) {
  .card-item__wrapper {
    padding: 20px 10px;
  }
}
.card-item__name {
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .card-item__name {
    font-size: 16px;
  }
}
.card-item__nameItem {
  display: inline-block;
  min-width: 8px;
  position: relative;
}
.card-item__number {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 27px;
  margin-bottom: 35px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__number {
    font-size: 21px;
    margin-bottom: 15px;
    padding: 10px 10px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__number {
    font-size: 19px;
    margin-bottom: 10px;
    padding: 10px 10px;
  }
}
.card-item__numberItem {
  width: 16px;
  display: inline-block;
}
.card-item__numberItem.-active {
  width: 30px;
}
@media screen and (max-width: 480px) {
  .card-item__numberItem {
    width: 13px;
  }
  .card-item__numberItem.-active {
    width: 16px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__numberItem {
    width: 12px;
  }
  .card-item__numberItem.-active {
    width: 8px;
  }
}
.card-item__content {
  color: #fff;
  display: flex;
  align-items: flex-start;
}
.card-item__date {
  flex-wrap: wrap;
  font-size: 18px;
  margin-left: auto;
  padding: 10px;
  display: inline-flex;
  width: 80px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__date {
    font-size: 16px;
  }
}
.card-item__dateItem {
  position: relative;
}
.card-item__dateItem span {
  display: inline-block;
}
.card-item__dateTitle {
  opacity: 0.7;
  font-size: 13px;
  padding-bottom: 6px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__dateTitle {
    font-size: 12px;
    padding-bottom: 5px;
  }
}
.card-item__band {
  background: rgba(0, 0, 19, 0.8);
  width: 100%;
  height: 50px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .card-item__band {
    margin-top: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__band {
    height: 40px;
    margin-top: 10px;
  }
}
.card-item__cvv {
  text-align: right;
  position: relative;
  z-index: 2;
  padding: 15px;
}
.card-item__cvv .card-item__type {
  opacity: 0.7;
}
@media screen and (max-width: 360px) {
  .card-item__cvv {
    padding: 10px 15px;
  }
}
.card-item__cvvTitle {
  padding-right: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}
.card-item__cvvBand {
  height: 45px;
  background: #fff;
  margin-bottom: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  color: #1a3b5d;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
}
@media screen and (max-width: 480px) {
  .card-item__cvvBand {
    height: 40px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__cvvBand {
    margin-bottom: 15px;
  }
}
.card-list {
  margin-bottom: -130px;
}
@media screen and (max-width: 480px) {
  .card-list {
    margin-bottom: -120px;
  }
}
.card-input {
  margin-bottom: 20px;
}
.card-input__label {
  font-size: 14px;
  font-weight: 500;
  color: #1a3b5d;
  width: 100%;
  display: block;
  user-select: none;
}
.card-input__input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: "Source Sans Pro", sans-serif;
}
.card-input__input:hover,
.card-input__input:focus {
  border-color: #3d9cff;
}
.card-input__input:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-input__input.-select {
  -webkit-appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}
.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}
.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}
.billInfo {
  text-align: center;
}
.billInfo > button {
  margin: 15px 15px 5px 15px;
}
.freeSpan {
  font-size: 0.9rem;
}
@media (max-width: 800px) {
  .advantages {
    width: 100%;
  }
  .priceMain {
    width: 100%;
  }
  .imageCont > img {
    width: 100%;
  }
  .faqs {
    flex-direction: column;
    width: 100%;
  }
  .topSpace {
    width: 100% !important;
  }
  #payment-form {
    width: 100% !important;
  }
}
</style>